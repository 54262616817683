<template>
  <!-- 剧集抽屉 -->
  <a-modal v-model:visible="visible" title="添加项目" @ok="handleOk" :confirmLoading="isLoading">
    <div class="content">
      <a-radio-group v-model:value="project" name="radioGroup">
        <a-radio v-for="item in pList" :value="item.id">{{ item.name }}</a-radio>
      </a-radio-group>
    </div>
  </a-modal>
</template>

<script setup>
import { ref, defineExpose } from 'vue'
import { message } from 'ant-design-vue'
import { projectList } from '@/api/copyright'

// emit
const emit = defineEmits(['success'])
// 抽屉展示状态
let visible = ref(false)
// 加载
let isLoading = ref(false)
// 项目列表
let pList = ref([])
// 选中项目
let project = ref(undefined)


// 打开抽屉
function showModal (ids) {
  // 展开
  visible.value = true
  // 获取项目列表
  getProjectList(ids)
}

// 关闭抽屉
function onClose () {
  if (!isLoading.value) {
    project.value = undefined
    visible.value = false
  }
}

// 获取项目列表
function getProjectList (ids) {
  isLoading.value = true
  projectList().then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      // 将父组件已有的项目id数据过滤
      pList.value = data.filter(item => !ids.includes(item.id))
    } else {
      message.error(res.message || msg)
    }
  })
}

// 提交
function handleOk () {
  if (!project.value) {
    return message.error('请选择项目')
  }
  // 过滤项目id对应的数据
  const data = pList.value.filter(item => item.id === project.value)[0]
  // 更新父组件
  emit('success', {
    project_id: data.id,
    project_name: data.name
  })
  // 关闭弹窗
  onClose()
}

// 暴露出去
defineExpose({
  showModal
})

</script>

<style lang="less" scoped>
.content {
  min-height: 100px;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  .ant-radio-wrapper {
    width: 100%;
    height: 32px;
  }
}
</style>