<template>
  <!-- 剧集抽屉 -->
  <a-drawer
    :title="id ? '编辑账号' : '新增账号'"
    width="700"
    :closable="false"
    :visible="visible"
    @close="onClose"
    :footer-style="{ textAlign: 'right' }"
  >
    <a-form
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 16 }"
    >
      <!-- 用户名 -->
      <a-form-item
        label="用户名"
        name="username"
        :rules="[{ required: true, message: '请输入用户名' }]"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.username"
          :maxlength="20"
        />
      </a-form-item>
      <!-- 密码 -->
      <a-form-item
        label="密码"
        name="password"
        :rules="[{ required: true, validator: validatorPassword }]"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.password"
        />
      </a-form-item>
      <!-- 昵称 -->
      <a-form-item
        label="昵称"
        name="nickname"
        :rules="[{ required: true, message: '请输入昵称' }]"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.nickname"
        />
      </a-form-item>
    </a-form>
    <!-- 尾部 -->
    <template #footer>
      <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
      <a-button type="primary" @click="touchSubmit">确定</a-button>
    </template>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { ref, reactive, defineExpose, nextTick } from 'vue'
import { message } from 'ant-design-vue'
import Loading from '@/components/Loading'
import { userEdit } from '@/api/request'
import { PasswordRule } from '@/utils/constantList'
import md5 from 'js-md5'
import Pub from '@/utils/public'

// emit
const emit = defineEmits(['success'])
// Form实例
const RefForm = ref(null)
// 抽屉展示状态
let visible = ref(false)
// 加载
let isLoading = ref(false)
// id
let id = ref(null)
// 表单
let formState = reactive({
  // 用户名
  username: undefined,
  // 密码
  password: undefined,
  // 昵称
  nickname: undefined
})


// 打开抽屉
function showDrawer (params) {
  // 展开
  visible.value = true
  // id
  id.value = params.id
  // 赋值
  nextTick(() => {
    if (params.id) {
      // 用户名
      formState.username = params.username
      // 密码
      formState.password = PasswordRule
      // 昵称
      formState.nickname = params.nickname
    }
  })
}

// 关闭抽屉
function onClose () {
  if (!isLoading.value) {
    // 重置
    RefForm.value.resetFields()
    id.value = undefined
    visible.value = false
  }
}

// 效验 - 密码
function validatorPassword (rule, value) {
  if (value && value === PasswordRule) {
    return Promise.resolve()
  } else if (!value) {
    return Promise.reject('请输入${label}')
  } else if (!Pub.REG_IS_PASSWORD(value)) {
    return Promise.reject('请输入6-12位仅包含字母或数字的密码')
  }
  return Promise.resolve()
}

// 提交
function touchSubmit () {
  RefForm.value.validate().then(() => {
    isLoading.value = true
    const params = {
      // 项目id，管理中心默认 0 
      project_id: 0,
      // 用户id
      id: id.value,
      // 表单数据
      ...formState,
      // 密码使用 MD5 加密
      password: formState.password === PasswordRule ? PasswordRule : md5(formState.password)
    }
    userEdit(params).then(res => {
      isLoading.value = false
      const { code, msg, data } = res
      if (code === 0) {
        message.success('操作成功')
        onClose()
        // 更新父组件
        emit('success')
      } else {
        message.error(res.message || msg)
      }
    })
  })
}

// 暴露出去
defineExpose({
  showDrawer
})

</script>

<style lang="less" scoped>
</style>