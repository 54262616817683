<template>
  <a-drawer
    title="项目权限"
    width="700"
    :closable="false"
    :visible="visible"
    @close="onClose"
    :footer-style="{ textAlign: 'right' }"
  >
    <a-tabs v-model:activeKey="activeKey" type="editable-card" @edit="onEdit">
      <a-tab-pane v-for="(project, index) in formState.data" :key="project.project_id" :tab="project.project_name" :closable="true">
        <template #closeIcon>
          <a-popconfirm
            title="确定要删除该项目的权限吗？"
            ok-text="确认"
            cancel-text="取消"
            @confirm="deleteProject(index)"
          >
            <close-outlined />
          </a-popconfirm>
        </template>
        <a-form
          :ref="'RefForm_' + project.project_id"
          :model="formState"
          :label-col="{ span: 0 }"
          :wrapper-col="{ span: 24 }"
        >
          <!-- 用户名 -->
          <a-form-item
            name="role_type"
          >
            <a-radio-group v-model:value="project.role_type" name="radioGroup" @change="changeRole(project.project_id, $event)">
              <a-radio value="1">管理员</a-radio>
              <a-radio value="2">成员</a-radio>
            </a-radio-group>
          </a-form-item>
          <!-- 菜单 -->
          <a-form-item
            name="menus"
          >
            <template v-for="first in project.menus">
              <!-- 一级菜单 -->
              <div class="first-menu">
                <div class="first-menu-title">{{ first.show_name }}</div>
                <div class="first-menu-action">
                  <a class="link" @click="selectAll(project.project_id, first.route)">全选</a>
                  <a class="link" @click="selectReverse(project.project_id, first.route)">反选</a>
                </div>
              </div>
              <!-- 二级菜单 -->
              <div class="second-menu">
                <a-form-item v-for="item in first.menu">
                  <a-checkbox
                    v-model:checked="item.select"
                    :disabled="project.role_type === '1'"
                  >
                    {{ item.show_name }}
                  </a-checkbox>
                </a-form-item>
              </div>
            </template>
          </a-form-item>
        </a-form>
      </a-tab-pane>
    </a-tabs>
    <!-- 尾部 -->
    <template #footer>
      <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
      <a-button type="primary" @click="touchSubmit">确定</a-button>
    </template>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
    <!-- 新增项目tab -->
    <ProjectAuthAdd ref="RefProjectAuthAdd" @success="addProjectSuccess" />
  </a-drawer>
</template>

<script setup>
import { ref, reactive, defineExpose, nextTick } from 'vue'
import { message } from 'ant-design-vue'
import Loading from '@/components/Loading'
import ProjectAuthAdd from './ProjectAuthAdd'
import Pub from '@/utils/public'
import { userMenuList, userMenuSave } from '@/api/request'

// emit
const emit = defineEmits(['success'])
// ProjectAuthAdd 实例
const RefProjectAuthAdd = ref(null)
// 抽屉展示状态
let visible = ref(false)
// 加载
let isLoading = ref(false)
// id
let id = ref(null)
// tabkey
let activeKey = ref(undefined)
// 表单
let formState = reactive({
  data: []
})
// 复制一份初始详情数据
let copyData = []
// 默认项目菜单数据
let defaultMenus = []

// 打开抽屉
function showDrawer (params) {
  // 展开
  visible.value = true
  // id
  id.value = params.id
  // 获取详情数据
  getDetail()
}

// 关闭抽屉
function onClose () {
  if (!isLoading.value) {
    // 重置
    id.value = undefined
    visible.value = false
    copyData = []
  }
}

// 重置tabkey
function resetTabkey (index) {
  activeKey.value = index || (formState.data.length ? formState.data[0].project_id : undefined)
}

// 获取详情
function getDetail () {
  isLoading.value = true
  const params = {
    user_id: id.value,
    guard_name: 'project'
  }
  userMenuList(params).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      // 项目权限
      let userMenus = data.user_menus || []
      // 项目默认权限
      defaultMenus = data.defult_menus
      // 将二级菜单选中状态的值改为布尔值
      userMenus.forEach(project => {
        project.menus.forEach(item => {
          item.menu.forEach(i => {
            i.select = !!i.select
          })
        })
      })
      // 复制一份数据
      copyData = Pub.deepClone(userMenus)
      // 表单显示
      formState.data = userMenus
      // tabkey选中
      resetTabkey()
    } else {
      message.error(res.message || msg)
    }
  })
}

// 切换角色
function changeRole (project_id, e) {
  // 角色
  const value = e.target.value
  // 拷贝数据中取出对应项目数据
  const copyProjectData = copyData.filter(item => item.project_id === project_id)[0]
  formState.data.forEach(project => {
    // 找到项目
    if (project.project_id === project_id) {
      // 管理员
      if (value === '1') {
        // 手动选中所有二级菜单
        project.menus.forEach(first => {
          first.menu.forEach(item => {
            item.select = true
          })
        })
      } else {
        // 切换到成员
        // 拷贝数据中有对应项目
        if (copyProjectData && value === copyProjectData.role_type) {
          // 反显编辑前的数据（这里赋值的时候要深拷贝，否则会联动）
          project.menus = Pub.deepClone(copyProjectData.menus)
        }
        // 拷贝数据中无对应项目，清空选中
        else {
          project.menus.forEach(first => {
            first.menu.forEach(item => {
              item.select = false
            })
          })
        }
      }
    }
  })
  
}

// 全选
function selectAll (project_id, route) {
  formState.data.forEach(project => {
    // 找到项目
    if (project.project_id === project_id) {
      project.menus.forEach(first => {
        // 找到二级菜单
        if (first.route === route) {
          first.menu.forEach(item => {
            // 选中
            item.select = true
          })
        }
      })
    }
  })
}

// 反选
function selectReverse (project_id, route) {
  formState.data.forEach(project => {
    // 找到项目
    if (project.project_id === project_id) {
      project.menus.forEach(first => {
        // 找到二级菜单
        if (first.route === route) {
          first.menu.forEach(item => {
            // 反选
            item.select = !item.select
          })
        }
      })
    }
  })
}

// 移除项目
function deleteProject (index) {
  // 删除对应项目
  formState.data.splice(index, 1)
  // 重置tabkey
  resetTabkey()
}

// tab编辑（移除走 deleteProject ，这里只处理新增）
function onEdit (targetKey, action) {
  if (action === 'add') {
    // 将已有的项目id集合传入，用于过滤
    const ids = formState.data.map(item => item.project_id)
    RefProjectAuthAdd.value.showModal(ids)
  }
}

// 新增项目成功后的回调
function addProjectSuccess (obj) {
  // 增加默认的菜单数据
  formState.data.push({
    ...obj,
    role_type: '2',
    menus: defaultMenus
  })
  // 选中这个项目tab
  resetTabkey(obj.project_id)
}

// 提交
function touchSubmit () {
  isLoading.value = true
  // 处理项目数据
  let arr = []
  formState.data.forEach(project => {
    let obj = {}
    // 项目id
    obj.project_id = project.project_id
    // 角色
    obj.role_type = project.role_type
    // 项目权限数组
    obj.permission_ids = []
    project.menus.forEach(item => {
      // 二级菜单选中计数器
      let num = 0
      item.menu.forEach(i => {
        if (i.select) {
          obj.permission_ids.push(i.id)
          num += 1
        }
      })
      // 若二级菜单下有选中，一级菜单id也要拼接进去
      if (num > 0) {
        obj.permission_ids.push(item.id)
      }
    })
    arr.push(obj)
  })
  // 参数准备
  const params = {
    // 用户
    user_id: id.value,
    // 来源：1管理中心-管理中心权限  2管理中心-项目权限 3运营中心-权限
    type: 2,
    // 项目权限
    role_permission: arr
  }
  userMenuSave(params).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      message.success('操作成功')
      onClose()
      // 更新父组件
      emit('success')
    } else {
      message.error(res.message || msg)
    }
  })
}

// 暴露出去
defineExpose({
  showDrawer
})

</script>

<style lang="less" scoped>
.first-menu {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
  padding: 0 16px;
  .link{
    color: #bbb;
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}
.second-menu {
  display: flex;
  padding: 16px 0 0 16px;
  flex-wrap: wrap;
  .ant-form-item {
    margin-right: 14px;
  }
}
</style>