<template>
  <!-- 面包屑 -->
  <a-breadcrumb>
    <a-breadcrumb-item>账号与权限</a-breadcrumb-item>
    <a-breadcrumb-item>账号管理</a-breadcrumb-item>
  </a-breadcrumb>
  <!-- 功能条 -->
  <div class="tool-view">
    <div style="flex: 1;"></div>
    <a-button type="primary" @click="touchEdit()"><plus-outlined />创建账号</a-button>
  </div>
  <!-- 列表 -->
  <a-table
    class="table-view"
    :data-source="dataSource"
    :columns="columns"
    :row-key="record => record.id"
    :pagination="pagination"
    :loading="isLoading"
    @change="handleTableChange"
  >
    <!-- 自定义行内容 -->
    <template #bodyCell="{ column, record }">
      <!-- 操作 -->
      <template v-if="column.key === 'operation'">
        <a class="operation-item" @click="touchGl(record)">管理中心权限</a>
        <a class="operation-item" @click="touchXm(record)">项目权限</a>
        <a class="operation-item" @click="touchEdit(record)">编辑</a>
        <!-- 删除 -->
        <a-popconfirm
          title="确定要删除吗？"
          @confirm="touchDelete(record)"
        >
          <a class="operation-item">删除</a>
        </a-popconfirm>
      </template>
    </template>
  </a-table>
  <!-- 新增、编辑项目设置 -->
  <Add ref="RefAdd" @success="getUserList"></Add>
  <!-- 管理中心权限 -->
  <ManageAuth ref="RefManageAuth" @success="getUserList"></ManageAuth>
  <!-- 项目权限 -->
  <ProjectAuth ref="RefProjectAuth" @success="getUserList"></ProjectAuth>
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue'
import Add from './components/Add'
import ManageAuth from './components/ManageAuth'
import ProjectAuth from './components/ProjectAuth'
import { message } from 'ant-design-vue'
import { userList, userDelete } from '@/api/request'

// 新增、编辑组件实例
let RefAdd = ref(null)
// 管理中心权限组件实例
let RefManageAuth = ref(null)
// 项目权限组件实例
let RefProjectAuth = ref(null)
// 加载
let isLoading = ref(false)
// 数据源
let dataSource = ref([])
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源（付费转化率、权重本期不做）
let columns = ref([
  {
    title: 'UID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: '用户名',
    dataIndex: 'username',
    key: 'username'
  },
  {
    title: '昵称',
    dataIndex: 'nickname',
    key: 'nickname'
  },
  {
    title: '拥有权限',
    dataIndex: 'project_name',
    key: 'project_name'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation'
  }
])

// 钩子函数
onBeforeMount (() => {
  // 获取账号列表
  getUserList()
})

// 获取账号列表
function getUserList () {
  isLoading.value = true
  const params = {
    page: pagination.current,
    page_size: pagination.pageSize
  }
  userList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      dataSource.value = data.data
      pagination.total = data.total
    } else {
      message.error(res.message || msg)
    }
  })
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getUserList()
}

// 管理中心权限
function touchGl (record) {
  RefManageAuth.value.showDrawer(record)
}

// 项目权限
function touchXm (record) {
  RefProjectAuth.value.showDrawer(record)
}

// 编辑
function touchEdit (record) {
  RefAdd.value.showDrawer(record || {})
}

// 删除账户
function touchDelete (record) {
  userDelete({
    id: record.id
  }).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      message.success('操作成功')
      getUserList()
    } else {
      message.error(res.message || msg)
    }
  })
}
</script>

<style scoped>
.tool-view {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.table-view {
  margin-top: 20px;
}
.tool-title:not(:first-child) {
  margin-left: 16px;
}
.operation-item {
  margin-right: 8px;
}
</style>